<template>
  <!--   第四层 -->
  <div class="home-four">
    <img src="../../pic/首页/蓝三角.png" alt="" style="width: 3.53%; height: 6.63%; position: absolute; top: 10.13%; left: 9.78%;">
    <span class="text-big" style="position: absolute; top: 13.63%; left: 12.34%;">助力中小微企业融资</span>
    <span class="text-small" style="position: absolute; top: 14.44%; left: 32.16%;">高效、精准、快捷</span>
    <img src="../../pic/首页/矩形%2029@2x.png" alt="" style="position: absolute; top: 21.75%; left: 9.78%; width: 0.48%; height: 3.63%;">
    <span class="text-big" style="position: absolute; top: 23.44%; left: 10.79%; font-size: 31px;">实时融资动态</span>
    <img src="../../pic/首页/矩形%2030@2x.png" alt="" style="position: absolute; top: 21.75%; left: 55.36%; width: 0.48%; height: 3.63%;">
    <span class="text-big" style="position: absolute; top: 23.44%; left: 56.32%; font-size: 31px;">累计服务</span>
    <img src="../../pic/首页/50842302b4c5d7e6ecb27eed1dfa3425be4a12383fb4f-UsFKdW@2x.png" alt="" style="position: absolute; top: 35.75%; left: 60.69%; width: 23.35%; height: 53.00%;">
    <span class="text-four" style="position: absolute; top: 37.44%; left: 68.49%;">今日对接服务数</span>
    <span class="text-big" style="position: absolute; top: 32.38%; left: 70.64%;">740</span>
    <span class="text-four" style="position: absolute; top: 49.31%; left: 57.79%;">入驻金融机构</span>
    <span class="text-big" style="position: absolute; top: 44.25%; left: 59.19%;">50＋</span>
    <span class="text-four" style="position: absolute; top: 49.31%; left: 79.92%;">融资产品丰富</span>
    <span class="text-big" style="position: absolute; top: 44.25%; left: 80.82%;">100＋</span>
    <span class="text-four" style="position: absolute; top: 75.81%; left: 58.82%;">累计服务客户</span>
    <span class="text-big" style="position: absolute; top: 70.75%; left: 59.68%;">970＋</span>
    <span class="text-four" style="position: absolute; top: 75.81%; left: 80.42%;">累计撮合金额</span>
    <span class="text-big" style="position: absolute; top: 70.75%; left: 79.92%;">1,000亿＋</span>
  </div>
</template>

<script setup>

</script>

<style scoped>
.home-four{
  width: 100%;
  height: 800px;
  background-color: rgba(244, 248, 255, 1);
  position: absolute;
  top: 2904px;
  left: 0;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 31px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-four{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #666666;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>