<template>
  <div class="product-first-route">
    <router-link to="/" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 46.69%">首页</router-link>
    <router-link to="/financing" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 52.62%">贷款融资</router-link>
    <router-link to="/financingplan" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 60.84%">融资方案</router-link>
    <router-link to="/" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 69.12%">信贷工具</router-link>
    <router-link to="/product" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 77.30%">产品方案</router-link>
    <router-link to="/login" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 85.47%">用户登录</router-link>
  </div>
</template>

<script setup>

</script>

<style scoped>
.product-first-route {
  width: 100%;
  height: 98px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.16);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 1;
}
.route-item{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
}
</style>