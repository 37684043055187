<template>
  <!--   第二层-->
  <div class="home-two">
    <img src="../../pic/首页/蓝三角.png" alt="" style="width: 66px;height: 53px;position: absolute; top: 11.84%; left: 9.76%;">
    <span class="text-big" style="position: absolute;top: 15.24%;left: 12.34%">融资产品</span>
    <span class="text-small" style="position: absolute;top: 16.19%;left: 22.01%">为您推荐当前热门产品</span>
    <span class="text-small" style="position: absolute;top: 16.19%;left: 83%;">更多产品</span>
    <img src="../../pic/首页/右箭头.png" style="position: absolute;top: 15.10%;left: 87.6%;width: 20px;height: 20px">
    <div class="product-container">
      <div v-for="(product, index) in products" :key="index" class="product" :style="{ left: `${9.88 + index * 20.41}%` }">
        <img src="../../pic/首页/绿框.png" alt="" style="position:absolute;top: 7.16%;left: 19.66%;width: 18.54%;height: 13.89%">
        <img src="../../pic/首页/钱.png" alt="" style="position:absolute;top: 49px;left: 86px;width: 36px;height: 35px">
        <span class="product-text" style="position: absolute;top: 52px;left: 147px">{{ product.name }}</span>
        <span class="product-text1" style="position: absolute;top: 73px;left: 147px">最高额度：<span class="product-text2">{{ product.limit }}</span></span>
        <div class="product-mes" style="position: absolute;top: 139px;left: 31px">
          <span class="product-mes-text" style="position:absolute;top: 17px;left: 57px">{{ product.rate }}</span>
          <span class="product-mes-text" style="position:absolute;top: 57px;left: 57px">{{ product.repayment }}</span>
          <span class="product-mes-text" style="position:absolute;top: 98px;left: 57px">{{ product.period }}</span>
        </div>
        <span class="product-text3" style="position: absolute;top: 311px;left: 140px">{{ product.feature }}</span>
        <span class="product-text4" style="position: absolute;top: 352px;left: 98px">超低利率，随借随还</span>
        <button class="product-button" style="position: absolute;top: 393px;left: 59px"><span class="product-button-text" style="position: absolute;top: 18px;left: 85px;">立即申请</span></button>
      </div>
    </div>
  </div>
</template>

<script setup>
const products = [
  {
    name: '新网好业贷',
    limit: '500万',
    rate: '年化利率3.6%-18%',
    repayment: '还款等额本金',
    period: '周期24期',
    feature: '低利率'
  },
  {
    name: '新网好业贷',
    limit: '500万',
    rate: '年化利率3.6%-18%',
    repayment: '还款等额本金',
    period: '周期24期',
    feature: '额度高'
  },
  {
    name: '新网好业贷',
    limit: '500万',
    rate: '年化利率3.6%-18%',
    repayment: '还款等额本金',
    period: '周期24期',
    feature: '审批快'
  },
  {
    name: '新网好业贷',
    limit: '500万',
    rate: '年化利率3.6%-18%',
    repayment: '还款等额本金',
    period: '周期24期',
    feature: '门槛低'
  }
];
</script>

<style scoped>
.home-two{
  width: 100%;
  height: 735px;
  position: absolute;
  top: 987px;
  left: 0;
  background-color: #F1F3FB;
  text-align: center;
}
.product-container {
  width: 100%;
  height: 475px;
  position: relative;
  top: 23.13%;

}
.product{
  width: 19.02%;
  height: 100%;
  border-radius: 7px;
  background-color: #FFFFFF;
  position: absolute; /* 确保绝对定位 */
}
.product-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.product-text1{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #777777;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0px;
  line-height: 18px;
}
.product-text2{
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #EA0A25;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0px;
  line-height: 18px;
}
.product-text3{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(57, 96, 255, 1);
  font-size: 26px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-text4{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-button{
  width: 240px;
  height: 61px;
  border: white;
  border-radius: 7px;
  background: linear-gradient(90deg, rgba(41, 83, 255, 1) 0%, rgba(35, 196, 253, 1) 100%);
}
.product-button-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-mes {
  width: 295px;
  height: 139px;
  border-radius: 13px;
  background-color: rgba(40, 97, 255, 0.05);
}
.product-mes-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #000000;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 18px;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>