<template>
  <!--    第三层-->
  <div class="home-three">
    <img src="../../pic/首页/蓝三角.png" alt="" style="width: 3.53%; height: 4.5%; position: absolute; top: 11.45%; left: 9.78%;">
    <span class="text-big" style="position: absolute; top: 13.65%; left: 12.34%;">融资工具</span>
    <span class="text-small" style="position: absolute; top: 14.39%; left: 22.01%;">为您推荐当前热门产品</span>
    <img src="../../pic/首页/tool1.png" alt="" style="position: absolute; top: 19.17%; left: 16.24%; width: 8.12%; height: 12.89%;">
    <span class="tool-text" style="font-weight: 600;position: absolute; top: 32.06%; left: 16.03%;">企业分析报告</span>
    <img src="../../pic/首页/tool2.png" alt="" style="position: absolute; top: 19.17%; left: 37.44%; width: 8.12%; height: 12.89%;">
    <span class="tool-text" style="font-weight: 600;position: absolute; top: 32.06%; left: 35.9%;">企业工商信息准入</span>
    <img src="../../pic/首页/tool3.png" alt="" style="position: absolute; top: 19.17%; left: 57.33%; width: 8.12%; height: 12.89%;">
    <span class="tool-text" style="font-weight: 600;position: absolute; top: 32.06%; left: 58.55%;">产品筛选</span>
    <img src="../../pic/首页/tool4.png" alt="" style="position: absolute; top: 19.17%; left: 75.74%; width: 8.12%; height: 12.89%;">
    <span class="tool-text" style="font-weight: 600;position: absolute; top: 32.06%; left: 75.64%;">供应链白名单</span>
    <span class="tool-text" style="position: absolute; top: 58.38%; left: 12.29%;">企业授权平台为获取税务、发票企业征信信息，<br>
          根据该信息为企业出具企业融资分析报告，精<br>
          准匹配融资产品。</span>
    <img src="../../pic/首页/tool-bg.png" alt="" style="position: absolute; top: 65.06%; left: 9.78%; width: 54.02%; height: 34.95%;">
    <img src="../../pic/首页/tool-right.png" alt="" style="position: absolute; top: 41.82%; left: 55.41%; width: 31.47%; height: 51.48%;">
  </div>
</template>

<script setup>

</script>

<style scoped>
.home-three{
  width: 100%;
  height: 1179px;
  position: absolute;
  top: 1725px;
  left: 0;
  background-color: #FFFFFF;
  text-align: center;
}
.tool-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 26px;
  color: #000000;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>