<template>
  <div class="login-first">
      <router-link to="/" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 46.69%">首页</router-link>
      <router-link to="/financing" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 52.62%">贷款融资</router-link>
      <router-link to="/" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 60.84%">融资方案</router-link>
      <router-link to="/" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 69.12%">信贷工具</router-link>
      <router-link to="/product" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 77.30%">产品方案</router-link>
      <router-link to="/login" class="route-item" active-class="" style="position:absolute;top: 43.5px;left: 85.47%">用户登录</router-link>
    <img src="@/pic/登录/组%20101@2x.png" alt="" class="loginimg">
  </div>
</template>

<script setup>

</script>

<style scoped>
.login-first{
  width: 100%;
  height: 782px;
  position: absolute;
  top: 43px;
  left: 0;
  background-image: url("@/pic/登录/b0c24259c57d2e0dce23dd570eb87367c3a29046f0956-LcyION@2x.png");
  background-size: 100% 782px; /* 强制背景图覆盖整个容器 */
  background-repeat: no-repeat;
}
.login-first-route {
  width: 100%;
  height: 98px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  top: 43px;
  left: 0;
}
.route-item{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
}
.loginimg{
  position: absolute;
  top: 150px;
  left: 227px;
  width: 595px;
  height: 595px;
}
</style>