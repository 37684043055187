import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';//首页
import Financing from '@/views/Financing.vue';//贷款融资
import Product from '@/views/ProductView.vue';//产品服务
import Login from '@/views/LoginView.vue';
import FinancingPlanView from "@/views/FinancingPlanView.vue";
//登录

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/financing',
    name: 'Financing',
    component: Financing,
  },
  {
    path: '/financingplan',
    name: 'Financingplan',
    component: FinancingPlanView,
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },{
    path: '/login',
    name: 'Login',
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
