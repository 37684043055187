<template>
  <!--footer-->
  <div class="footer">
    <span class="footer-text1" style="position:absolute; left: 9.78%; top: 18.73%;">认识我们</span>
    <span class="footer-text2" style="position:absolute; left: 9.78%; top: 30.18%;">财金科技</span>
    <span class="footer-text2" style="position:absolute; left: 9.78%; top: 41.64%;">企业融资</span>
    <span class="footer-text2" style="position:absolute; left: 9.78%; top: 53.27%;">税务授权</span>
    <span class="footer-text2" style="position:absolute; left: 9.78%; top: 64.55%;">我要赚钱</span>
    <span class="footer-text1" style="position:absolute; left: 20.57%; top: 18.73%;">联系我们</span>
    <span class="footer-text2" style="position:absolute; left: 20.57%; top: 30.18%;">财金科技</span>
    <span class="footer-text2" style="position:absolute; left: 20.57%; top: 41.64%;">企业融资</span>
    <span class="footer-text2" style="position:absolute; left: 20.57%; top: 53.27%;">税务授权</span>
    <span class="footer-text2" style="position:absolute; left: 20.57%; top: 64.55%;">我要赚钱</span>
    <img src="../../pic/首页/图像%203@2x.png" alt="" style="width: 7.91%; height: 26.91%; position:absolute; left: 72.45%; top: 40.00%;">
    <img src="../../pic/首页/图像%204@2x.png" alt="" style="width: 7.91%; height: 26.91%; position:absolute; left: 82.39%; top: 40.00%;"></div>
</template>

<script setup>

</script>

<style scoped>
.footer{
  width: 100%;
  height: 550px;
  background-color: #252931;
  position: absolute;
  top: 4833px;
  left: 0;
}
.footer-text1{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #FFFFFF;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.footer-text2{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #8E8F93;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>