<template>
  <div class="home-top"></div>
</template>

<script setup>

</script>

<style scoped>
.home-top {
  width: 100%;
  height: 43px;
  background-color: #333333;
  position: absolute;
  top: 0;
  left: 0;
}
</style>