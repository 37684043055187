<template>
  <!--   第六层 -->
  <div class="home-six">
    <img src="../../pic/首页/组%2012@2x.png" alt="" style="width: 6.41%; height: 40.96%; position: absolute; top: 21.84%; left: 11.11%;">
    <img src="../../pic/首页/路径%2013@2x.png" alt="" style="width: 5.98%; height: 38.23%; position: absolute; top: 24.57%; left: 37.34%;">
    <img src="../../pic/首页/组%2013@2x.png" alt="" style="width: 4.06%; height: 36.87%; position: absolute; top: 25.60%; left: 65.12%;">
    <span class="text-big" style="font-size: 29px; position: absolute; top: 30.56%; left: 18.91%;">国家高新技术企业</span>
    <span class="text-big" style="font-weight: 400; font-size: 18px; position: absolute; top: 47.10%; left: 18.91%;">国家高新技术企业</span>
    <span class="text-big" style="font-weight: 400; font-size: 18px; position: absolute; top: 60.07%; left: 18.91%;">中科院绿研院旗下孵化企业</span>
    <span class="text-big" style="font-size: 29px; position: absolute; top: 30.56%; left: 45.30%;">全产品供应链</span>
    <span class="text-big" style="font-weight: 400; font-size: 18px; position: absolute; top: 50.85%; left: 45.35%; line-height: 1.5;">数百款金融产品，票贷、税贷、商<br>户贷、中标贷等，背靠银行及头部<br>持牌机构，无风险、安全可靠</span>
    <span class="text-big" style="font-size: 29px; position: absolute; top: 30.56%; left: 71.47%;">科技赋能</span>
    <span class="text-big" style="font-weight: 400; font-size: 18px; position: absolute; top: 50.99%; left: 71.35%; line-height: 1.5;">贷前客户融资风险评估系统，税票数据采<br>集、企业上下游客户数据分析评估，制定 <br>企业融资服务方案，高效完成客户融资体 <br>验。</span>
  </div>
</template>

<script setup>

</script>

<style scoped>
.home-six{
  width: 100%;
  height: 293px;
  position: absolute;
  top: 4540px;
  left: 0;
  background-color: #FFFFFF;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>